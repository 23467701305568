window.SlideShowHeader = () => {
    return {
        swiper: null,
        init() {
            this.swiper = new Swiper(this.$refs.swiperContainer, {
                loop: true,
                slidesPerView: 1,
                speed: 400,
                spaceBetween: 0,
                pagination: { el: '.swiper-pagination', clickable: true },
                effect: 'fade',
                a11y: true,
                autoplay: {
                    delay: 10000,
                },
                navigation: {
                    nextEl: this.$refs.swiperNext,
                    prevEl: this.$refs.swiperPrev,
                },
                centeredSlides: true,
            });

            this.swiper.on('slideResetTransitionStart', function () {
                navigation.enabled = false;
            });

            this.swiper.on('slideChangeTransitionEnd', function () {
                navigation.enabled = true;
            });
        },
    };
};
